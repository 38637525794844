import apiCalls from "./apiCall";

export async function renewDomain(domain, years) {
    try {
        const response = await apiCalls.dominiApi.renewDomain(domain, years);
        return response.status;
    } catch (error) {
        console.error('Error renewing domain:', error);
        throw error;
    }
}
