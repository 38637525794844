//LOGIN
export let POST_USER_LOGIN = "/api/login";
export let POST_USER_ME = "/api/me";
export let POST_USER_REGISTER = "/api/register";
//EMAIL QUEUE
export let GET_EMAIL_QUEUE = "/api/email-queue";

// API-DOMINI
export let GET_TLD_LIST = "/api/get-list-extension";

export let CHECK_DOMINIO = "/api/domain-check";
// ACTIONS-DOMINI

// ADMIN
export let SET_LIST_DOMAINS = "/api/list-domains";
export let GET_DOMAIN_DETAILS=  "/api/details-domain";
// CLIENTE

export let SET_LIST_DOMAINS_CLIENTES = "/api/list-domains-role";
export let GET_DOMAIN_DETAILS_CLIENTS=  "/api/details-domain-role";
// ACTIONS - DASHBOARD - ADMIN
export let SET_OVERVIEW_DOMAINS = "/api/overviewDomain";

export let SET_NOTIFICHE_RINNOVI = "/api/dashboard-scadenza-dominio";
export let SET_NOTIFICHE_RINNOVI_NO_RENEWAL = "/api/dashboard-scadenza-dominio-no-renewal";
export let SET_ANDAMENTO_DOMAINS = "/api/domains/registered";
// ACTIONS - DASHBOARD - CLIENTI

export let SET_NOTIFICHE_RINNOVI_CLIENTI = "/api/dashboard-scadenza-dominio-cliente";
export let SET_NOTIFICHE_RINNOVI_NO_RENEWAL_CLIENTI = "/api/dashboard-scadenza-dominio-no-renewal-cliente";
export let SET_OVERVIEW_DOMAINS_CLIENTI = "/api/overviewDomain-cliente";
//CONTACTS - LIST - ADMIN
export let SET_LIST_CONTACTS = "/api/contacts/unique-by-type";

// CONTACTS - LIST - CLIENTE
export let SET_LIST_CONTACTS_CLIENTI = "/api/list-contacts-role";

// ACTIONS-CLIENTI

export let SET_LIST_CLIENTES = "/api/list-contact-name";
export let SET_LIST_CLIENTES_FOR_USER = "/api/list-contact-for-user";
export let SET_LIST_PREZZI_LISTE = '/api/list-price-lists';
// ACTIONS - UTENTI
export let SET_LIST_UTENTI = "/api/list-users";

export let SET_UTENTE_DETTAGLIO = "/api/user-details";
export let SET_UTENTE_UPDATE = "/api/user-update";
export let SET_UTENTE_DELETE = "/api/user-delete";
export let SET_USER_CREATE = "/api/users-create";
// ACTIONS - CALENDAR

export let DATA_CALENDAR_DOMAINS = "/api/calendar-events";
// RICHIESTE CLIENTI LATO ADMIN

export let SET_LIST_RICHIESTE_CLIENTI = "/api/client-requests-list";
export let SET_RICHIESTE_CLIENTI_CREATE = "/api/client-requests-store";
export let SET_RICHIESTE_CLIENTI_UPDATE = "/api/request-update";
export let SET_RICHIESTE_CLIENTI_DELETE = "/api/request-delete";
// RICHIESTE DOMINI LATO CLIENTE

export let SET_LIST_RICHIESTE_ROLE_CLIENTE = "/api/requests-list-role-cliente";
export let DECRYPT_DOMAIN_DATA = "/api/decrypt-domain-data";
// RICHIESTE SETTINGS

export let SET_NAMESERVER_SETTINGS_LIST = "/api/listNameserverPack";
export let SET_NAMESERVER_SETTINGS_STORE = 'api/storeNameserverPack';
export let SET_NAMESERVER_SETTINGS_UPDATE = 'api/updateNameserverPack';
export let SET_NAMESERVER_SETTINGS_DELETE = 'api/deleteNameserverPack';
export let SETTING_PRICES_LISTINO = "/api/listino-prezzi";
// ACTIONS - RICHIESTE DOMINI

export let RENEW_DOMAIN = 'api/renew-domain';
export let RENEWAL_MODE_DOMAIN = 'api/renewal-mode';
//LiSTA REQUESTS API

export let SET_REQUESTS_API ='api/list-api-requests'

export let GET_NOTIFICHE_POLL = 'api/notifiche-poll';
