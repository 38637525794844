import React from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, Row, Col, Spinner } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import DomainFilters from './DomainFilters';
import DomainActionsMenu from "./DomainActionsMenu";

const ExpandedComponent = ({ data }) => {
    const formatAddress = (contact) => {
        const parts = [
            contact.street,
            contact.city,
            contact.postal_code,
            contact.country_code
        ].filter(Boolean);
        return parts.join(', ') || 'N/A';
    };

    const getContactCardBackground = (index) => {
        const colors = ['#f8f9fa', '#e9ecef', '#f1f3f5', '#dee2e6'];
        return colors[index % colors.length];
    };

    return (
        <Card className="m-3">
            <CardBody>
                <h5 className="mb-4">Informazioni di Contatto</h5>
                <Row className="d-flex flex-nowrap overflow-auto">
                    {data.contacts?.map((contact, index) => (
                        <Col key={index} xs="auto" className="mb-3">
                            <Card className="shadow-sm" style={{ width: '300px', backgroundColor: getContactCardBackground(index) }}>
                                <CardBody>
                                    <h6 className="text-primary mb-2">{contact.type.toUpperCase()} Contact</h6>
                                    <div className="mb-2">
                                        <strong>Nome:</strong> {contact.name || 'N/A'}
                                    </div>
                                    <div className="mb-2">
                                        <strong>Email:</strong> {contact.email || 'N/A'}
                                    </div>
                                    <div className="mb-2">
                                        <strong>Telefono:</strong> {contact.voice || 'N/A'}
                                    </div>
                                    <div className="mb-2">
                                        <strong>Indirizzo:</strong> {formatAddress(contact)}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </CardBody>
        </Card>
    );
};

const DomainTable = ({
                         domains,
                         totalRows,
                         perPage,
                         loading,
                         onPageChange,
                         onPerRowsChange,
                         onFilterChange,
                         filters,
                         formatDate,
                         currentPage
                     }) => {
    const navigate = useNavigate();

    const handleViewDetails = (domain) => {
        navigate(`/dettagli-domini/${domain.id}`);
    };

    const isExpiringWithinTwoMonths = (expirationDate) => {
        if (!expirationDate) return false;
        const today = new Date();
        const expDate = new Date(expirationDate);
        const twoMonthsFromNow = new Date();
        twoMonthsFromNow.setMonth(today.getMonth() + 2);
        return expDate <= twoMonthsFromNow;
    };



    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Nome</span>,
            selector: row => row.name,
            sortable: true,
            grow: 2
        },
        {
            name: <span className='font-weight-bold fs-13'>Stato</span>,
            sortable: true,
            cell: (row) => {
                let badgeClass = "";
                switch (row.status_code) {
                    case '801':
                        badgeClass = "bg-warning";
                        break;
                    case '901':
                        badgeClass = "bg-info";
                        break;
                    case '1000':
                        badgeClass = "bg-success";
                        break;
                    case '1001':
                        badgeClass = "bg-danger";
                        break;
                    default:
                        badgeClass = "bg-secondary";
                }
                return <span className={`badge ${badgeClass}`}>{row.status_description}</span>;
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Scadenza</span>,
            selector: row => formatDate(row.expires_at),
            sortable: true,

        },
        {
            name: <span className='font-weight-bold fs-13'>Auto Rinnovo</span>,
            selector: row => row.renewal_mode === 'autoRenew' ? 'Sì' : 'No',
            sortable: true,
            center: true,

        },
        {
            name: <span className='font-weight-bold fs-13'>Azioni</span>,
            cell: (row) => (
                <DomainActionsMenu
                    row={row}
                    handleViewDetails={handleViewDetails}
                    isExpiringWithinTwoMonths={isExpiringWithinTwoMonths}
                />
            ),

            right: true
        }
    ];

    const customStyles = {
        table: {
            style: {
                backgroundColor: 'white',
                minWidth: '100%',
            },
        },
        rows: {
            style: {
                minHeight: '50px',
                fontSize: '13px',
                padding: '8px 0',
                '&:not(:last-of-type)': {
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: '#e9ecef',
                },
            },
            stripedStyle: {
                backgroundColor: '#f8f9fa',
            }
        },
        cells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '4px',
                paddingBottom: '4px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                fontSize: '13px',
                fontWeight: '600',
                whiteSpace: 'nowrap',
                height: '48px',
                backgroundColor: '#f8f9fa',
            },
            activeSortStyle: {
                color: '#2684FF',
                '&:hover:not(:focus)': {
                    color: '#2684FF',
                },
            },
        },
        pagination: {
            style: {
                borderTop: 'none',
                marginTop: '8px',
            },
            pageButtonsStyle: {
                borderRadius: '50%',
                height: '32px',
                width: '32px',
                padding: '4px',
                margin: '0 4px',
                cursor: 'pointer',
                transition: '0.4s',
                color: '#007bff',
                fill: '#007bff',
                backgroundColor: 'transparent',
                '&:disabled': {
                    cursor: 'unset',
                    color: '#dedede',
                    fill: '#dedede',
                },
                '&:hover:not(:disabled)': {
                    backgroundColor: '#007bff1a',
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: '#007bff1a',
                },
            },
        },
    };

    const conditionalRowStyles = [
        {
            when: row => isExpiringWithinTwoMonths(row.expires_at),
            style: {
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
            },
        },
    ];

    return (
        <div>
            <DomainFilters
                onFilterChange={onFilterChange}
                filters={filters}
            />

            <Card>
                <CardBody className="p-3">
                    <DataTable
                        columns={columns}
                        data={domains}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={onPageChange}
                        onChangeRowsPerPage={onPerRowsChange}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        expandableIcon={{
                            collapsed: <ChevronDown size={18} />,
                            expanded: <ChevronUp size={18} />
                        }}
                        conditionalRowStyles={conditionalRowStyles}
                        progressPending={loading}
                        progressComponent={
                            <div className="text-center py-5">
                                <Spinner color="primary" />
                            </div>
                        }
                        noDataComponent={
                            <div className="text-center py-4">
                                <h6 className="mb-1">Nessun dominio trovato</h6>
                                <p className="text-muted small mb-0">Prova a modificare i filtri di ricerca</p>
                            </div>
                        }
                        paginationDefaultPage={currentPage}
                        paginationPerPage={perPage}
                        customStyles={customStyles}
                        responsive
                        striped
                    />
                </CardBody>
            </Card>
        </div>
    );
};

export default DomainTable;
