import apiCalls from "./apiCall";

export async function changeRenewalMode(domain, renewalMode) {

    try {
        const response = await apiCalls.dominiApi.renewalModeDomain(domain, renewalMode);
        return response.status;
    } catch (error) {
        console.error('Error changing renewal mode:', error);
        throw error;
    }

}

