import React, {useState} from 'react';
import {
    Edit,
    Info,
    RotateCcw,
    Clock,
    ExternalLink,
    Plus,
    Minus
} from 'lucide-react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledTooltip,
    Input,
    Alert
} from 'reactstrap';
import {changeRenewalMode} from "../../../../../contexts/setRenewalMode";
import {renewDomain} from "../../../../../contexts/renewDomain";

const DomainActionsMenu = ({row, handleViewDetails, isExpiringWithinTwoMonths}) => {
    // Determina il metodo di rinnovo corrente dal row.renewal_mode
    const currentRenewalMethod = row.renewal_mode === 'autoRenew' ? 'autoRenew' : 'manualRenew';

    const [modal, setModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [renewalYears, setRenewalYears] = useState(1);
    const [selectedRenewalMethod, setSelectedRenewalMethod] = useState(currentRenewalMethod);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    // Reset dello stato quando si apre il modale
    const resetRenewalMethodState = () => {
        setSelectedRenewalMethod(currentRenewalMethod);
    };

    const isRenewalPeriod = () => {
        return isExpiringWithinTwoMonths(row.expires_at);
    };

    const getRenewalTooltipMessage = () => {
        if (!isRenewalPeriod()) {
            return 'Il dominio non è ancora in periodo di rinnovo';
        }
        return 'Rinnova';
    };

    const toggle = () => {
        setModal(!modal);
        if (!modal) {
            setRenewalYears(1);
            setShowAlert(false);
        } else {
            resetRenewalMethodState();
        }
    };

    const toggleConfirm = () => {
        setConfirmModal(!confirmModal);
    };

    const handleActionClick = (action) => {
        if (action === 'details') {
            handleViewDetails(row);
            return;
        }
        if (action === 'renewalMethod') {
            resetRenewalMethodState();
        }
        setSelectedAction(action);
        setModal(true);
    };

    const handleYearsChange = (value) => {
        const newValue = parseInt(value);
        if (!isNaN(newValue) && newValue >= 1 && newValue <= 10) {
            setRenewalYears(newValue);
        }
    };

    const incrementYears = () => {
        if (renewalYears < 10) {
            setRenewalYears(prev => prev + 1);
        }
    };

    const decrementYears = () => {
        if (renewalYears > 1) {
            setRenewalYears(prev => prev - 1);
        }
    };

    const handleRenewalMethodChange = (method) => {
        // Non permettere la selezione se è lo stesso metodo corrente
        if (method !== currentRenewalMethod) {
            setSelectedRenewalMethod(method);
        }
    };

    const canSaveRenewalMethod = () => {
        return selectedRenewalMethod !== currentRenewalMethod;
    };

    const handleConfirmRenewalMethod = async () => {
        setConfirmModal(false);
        setModal(false);

        try {
            const newMode = selectedRenewalMethod === 'autoRenew' ? 'autorenew' : 'autoexpire';
            const response = await changeRenewalMode(row.name, newMode);

            if (response === 'success') {
                setAlertMessage(`Il cambio del metodo di rinnovo per il dominio ${row.name} è stato inviato correttamente. Nuovo metodo: ${selectedRenewalMethod === 'autoRenew' ? 'Rinnovo Automatico' : 'Rinnovo Manuale'}`);
            } else {
                setAlertMessage(`Errore: Risposta non valida per il cambio del metodo di rinnovo per il dominio ${row.name}`);
                throw new Error('Risposta non valida');
            }
        } catch (error) {
            console.error('Errore durante la modifica del metodo di rinnovo:', error);
            setAlertMessage(`Errore: Si è verificato un problema durante la modifica del metodo di rinnovo per il dominio ${row.name}`);
        }

        setShowAlert(true);
        console.log(`Metodo di rinnovo cambiato a: ${selectedRenewalMethod} per il dominio ${row.name}`);

        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };

    const handleConfirmRenewal = async () => {
        setConfirmModal(false);
        setModal(false);

        try {
            const response = await renewDomain(row.name, renewalYears);

            if (response === 'success') {
                setAlertMessage(`Il rinnovo del dominio ${row.name} è stato inviato correttamente per ${renewalYears} ${renewalYears === 1 ? 'anno' : 'anni'}`);
            } else {
                setAlertMessage(`Errore: Si è verificato un problema durante la rinnovo del dominio ${row.name}`);
                throw new Error('Risposta non valida');
            }

        } catch (error) {
            console.error('Errore durante la rinnovo del dominio:', error);
            setAlertMessage(`Errore: Si è verificato un problema durante la rinnovo del dominio ${row.name}`);
        }


        setAlertMessage(`Il dominio ${row.name} è stato rinnovato per ${renewalYears} ${renewalYears === 1 ? 'anno' : 'anni'}`);
        setShowAlert(true);

        console.log(`Dominio ${row.name} rinnovato per ${renewalYears} anni`);

        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };

    const calculateNewExpiryDate = () => {
        const currentExpiry = new Date(row.expires_at);
        const newExpiry = new Date(currentExpiry);
        newExpiry.setFullYear(newExpiry.getFullYear() + renewalYears);
        return newExpiry.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const renderModalContent = () => {
        if (!selectedAction) return null;

        const modalContents = {
            edit: {
                title: "Modifica Dominio",
                content: (
                    <div className="p-3">
                        <h5 className="mb-4">Modifica dettagli per {row.name}</h5>
                        <p className="text-muted">Funzionalità di modifica in sviluppo...</p>
                    </div>
                )
            },
            renew: {
                title: `Rinnova Dominio ${row.name}`,
                content: (
                    <div className="p-3">
                        <h5 className="mb-4">Rinnovo dominio {row.name}</h5>
                        <div className="mb-4">
                            <label className="form-label">Durata del rinnovo</label>
                            <div className="d-flex align-items-center" style={{maxWidth: '200px'}}>
                                <Button
                                    color="light"
                                    className="p-1"
                                    onClick={decrementYears}
                                    disabled={renewalYears <= 1}
                                    style={{width: '32px', height: '32px'}}
                                >
                                    <Minus size={16}/>
                                </Button>
                                <Input
                                    type="number"
                                    value={renewalYears}
                                    onChange={(e) => handleYearsChange(e.target.value)}
                                    min="1"
                                    max="10"
                                    className="mx-2 text-center"
                                    style={{width: '60px'}}
                                />
                                <Button
                                    color="light"
                                    className="p-1"
                                    onClick={incrementYears}
                                    disabled={renewalYears >= 10}
                                    style={{width: '32px', height: '32px'}}
                                >
                                    <Plus size={16}/>
                                </Button>
                                <span className="ms-2">anni</span>
                            </div>
                        </div>
                        <div className="alert alert-info">
                            <p className="mb-0">Il dominio {row.name} verrà rinnovato
                                per {renewalYears} {renewalYears === 1 ? 'anno' : 'anni'}</p>
                            <p className="mb-0 mt-2">
                                <small>
                                    Nuova data di scadenza: {calculateNewExpiryDate()}
                                </small>
                            </p>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <Button color="secondary" className="me-2" onClick={toggle}>
                                Annulla
                            </Button>
                            <Button color="primary" onClick={toggleConfirm}>
                                Procedi con il Rinnovo
                            </Button>
                        </div>
                    </div>
                )
            },
            renewalMethod: {
                title: `Modifica Metodo di Rinnovo - ${row.name}`,
                content: (
                    <div className="p-3">
                        <h5 className="mb-4">Modifica metodo di rinnovo per {row.name}</h5>
                        <div className="form-check mb-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="renewalMethod"
                                id="autoRenew"
                                checked={selectedRenewalMethod === 'autoRenew'}
                                onChange={() => handleRenewalMethodChange('autoRenew')}
                                disabled={currentRenewalMethod === 'autoRenew'}
                            />
                            <label
                                className={`form-check-label ${currentRenewalMethod === 'autoRenew' ? 'text-muted' : ''}`}
                                htmlFor="autoRenew">
                                Rinnovo Automatico
                                <small className="d-block text-muted">
                                    {currentRenewalMethod === 'autoRenew'
                                        ? "Questa è l'impostazione corrente"
                                        : "Il dominio verrà rinnovato automaticamente alla scadenza"}
                                </small>
                            </label>
                        </div>
                        <div className="form-check mb-4">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="renewalMethod"
                                id="manualRenew"
                                checked={selectedRenewalMethod === 'manualRenew'}
                                onChange={() => handleRenewalMethodChange('manualRenew')}
                                disabled={currentRenewalMethod === 'manualRenew'}
                            />
                            <label
                                className={`form-check-label ${currentRenewalMethod === 'manualRenew' ? 'text-muted' : ''}`}
                                htmlFor="manualRenew">
                                Rinnovo Manuale
                                <small className="d-block text-muted">
                                    {currentRenewalMethod === 'manualRenew'
                                        ? "Questa è l'impostazione corrente"
                                        : "Dovrai rinnovare manualmente il dominio prima della scadenza"}
                                </small>
                            </label>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button color="secondary" className="me-2" onClick={toggle}>
                                Annulla
                            </Button>
                            <Button
                                color="primary"
                                onClick={toggleConfirm}
                                disabled={!canSaveRenewalMethod()}
                            >
                                Salva Modifiche
                            </Button>
                        </div>
                    </div>
                )
            }
        };

        return {
            title: modalContents[selectedAction].title,
            content: modalContents[selectedAction].content
        };
    };

    const renderConfirmationContent = () => {
        if (selectedAction === 'renewalMethod') {
            return {
                title: `Conferma Modifica - ${row.name}`,
                content: (
                    <>
                        <p>Sei sicuro di voler cambiare il metodo di rinnovo del dominio <strong>{row.name}</strong> in
                            "{selectedRenewalMethod === 'autoRenew' ? 'Rinnovo Automatico' : 'Rinnovo Manuale'}"?</p>
                        <div className="d-flex justify-content-end mt-4">
                            <Button color="secondary" className="me-2" onClick={toggleConfirm}>
                                Annulla
                            </Button>
                            <Button color="primary" onClick={handleConfirmRenewalMethod}>
                                Conferma
                            </Button>
                        </div>
                    </>
                )
            };
        } else if (selectedAction === 'renew') {
            return {
                title: `Conferma Rinnovo - ${row.name}`,
                content: (
                    <>
                        <p>Sei sicuro di voler rinnovare il
                            dominio <strong>{row.name}</strong> per {renewalYears} {renewalYears === 1 ? 'anno' : 'anni'}?
                        </p>
                        <p className="text-muted">Nuova scadenza: {calculateNewExpiryDate()}</p>
                        <div className="d-flex justify-content-end mt-4">
                            <Button color="secondary" className="me-2" onClick={toggleConfirm}>
                                Annulla
                            </Button>
                            <Button color="primary" onClick={handleConfirmRenewal}>
                                Conferma Rinnovo
                            </Button>
                        </div>
                    </>
                )
            };
        }
        return null;
    };

    return (
        <>
            {showAlert && (
                <Alert color="success" className="position-fixed top-0 start-50 translate-middle-x mt-3"
                       style={{zIndex: 1050}}>
                    {alertMessage}
                </Alert>
            )}

            <div className="d-flex" style={{gap: '2px'}}>
                <Button
                    id={`editBtn-${row.id}`}
                    color="info"
                    size="sm"
                    className="p-1"
                    style={{width: '28px', height: '28px'}}
                    onClick={() => handleActionClick('edit')}
                >
                    <Edit size={14}/>
                </Button>
                <UncontrolledTooltip target={`editBtn-${row.id}`}>
                    Modifica
                </UncontrolledTooltip>

                <Button
                    id={`detailsBtn-${row.id}`}
                    color="secondary"
                    size="sm"
                    className="p-1"
                    style={{width: '28px', height: '28px'}}
                    onClick={() => handleActionClick('details')}
                >
                    <Info size={14}/>
                </Button>
                <UncontrolledTooltip target={`detailsBtn-${row.id}`}>
                    Dettagli
                </UncontrolledTooltip>

                <Button
                    id={`renewBtn-${row.id}`}
                    color="success"
                    size="sm"
                    className="p-1"
                    style={{width: '28px', height: '28px'}}
                    onClick={() => handleActionClick('renew')}
                    disabled={!isRenewalPeriod() || row.renewal_mode !== 'autoExpire'}
                >
                    <RotateCcw size={14}/>
                </Button>
                <UncontrolledTooltip target={`renewBtn-${row.id}`}>
                    {getRenewalTooltipMessage()}
                </UncontrolledTooltip>

                <Button
                    id={`changeMethodBtn-${row.id}`}
                    color="warning"
                    size="sm"
                    className="p-1"
                    style={{width: '28px', height: '28px'}}
                    onClick={() => handleActionClick('renewalMethod')}
                >
                    <Clock size={14}/>
                </Button>
                <UncontrolledTooltip target={`changeMethodBtn-${row.id}`}>
                    Cambia metodo di rinnovo
                </UncontrolledTooltip>

                <Button
                    id={`visitBtn-${row.id}`}
                    color="primary"
                    size="sm"
                    className="p-1"
                    style={{width: '28px', height: '28px'}}
                    onClick={() => window.open(`http://${row.name}`, '_blank')}
                >
                    <ExternalLink size={14}/>
                </Button>
                <UncontrolledTooltip target={`visitBtn-${row.id}`}>
                    Visita Sito
                </UncontrolledTooltip>

                {selectedAction && (
                    <Modal isOpen={modal} toggle={toggle} size="lg">
                        <ModalHeader toggle={toggle}>
                            {renderModalContent()?.title}
                        </ModalHeader>
                        <ModalBody>
                            {renderModalContent()?.content}
                        </ModalBody>
                    </Modal>
                )}

                <Modal isOpen={confirmModal} toggle={toggleConfirm} size="sm">
                    <ModalHeader toggle={toggleConfirm}>
                        {renderConfirmationContent()?.title}
                    </ModalHeader>
                    <ModalBody>
                        {renderConfirmationContent()?.content}
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
};

export default DomainActionsMenu;
