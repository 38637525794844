import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import SimpleBar from "simplebar-react";
import apiCalls from "../contexts/apiCall";
import moment from 'moment';
import 'moment/locale/it';

import '../assets/css/notifiche.css';

moment.locale('it');

const NotificationDropdown = () => {
  const [menu, setMenu] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const data = await apiCalls.notifichePollApi.getNotifichePoll();
        if (data && Array.isArray(data)) {
          const formattedNotifications = data.map(notification => formatNotification(notification));
          setNotifications(formattedNotifications);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
    // const interval = setInterval(fetchNotifications, 30000);
    // return () => clearInterval(interval);
  }, []);

  const getNotificationConfig = (classType) => {
    const configs = {
      'domain:create': {
        icon: 'ri-add-circle-line',
        bgColor: 'bg-primary-subtle',
        iconColor: 'text-primary',
        category: 'info',
        label: 'Creazione Dominio'
      },
      'domain:update': {
        icon: 'ri-edit-line',
        bgColor: 'bg-warning-subtle',
        iconColor: 'text-warning',
        category: 'warning',
        label: 'Aggiornamento Dominio'
      },
      'domain:delete': {
        icon: 'ri-delete-bin-line',
        bgColor: 'bg-danger-subtle',
        iconColor: 'text-danger',
        category: 'danger',
        label: 'Eliminazione Dominio'
      },
      'default': {
        icon: 'ri-information-line',
        bgColor: 'bg-success-subtle',
        iconColor: 'text-success',
        category: 'success',
        label: 'Operazione Dominio'
      }
    };

    return configs[classType] || configs.default;
  };

  const getStatusBadge = (status) => {
    const statusConfig = {
      'processing': {
        label: 'In elaborazione',
        class: 'badge-warning',
        icon: 'ri-loader-4-line'
      },
      'completed': {
        label: 'Completato',
        class: 'badge-success',
        icon: 'ri-check-line'
      },
      'failed': {
        label: 'Fallito',
        class: 'badge-danger',
        icon: 'ri-close-circle-line'
      },
      'default': {
        label: status,
        class: 'badge-info',
        icon: 'ri-information-line'
      }
    };

    return statusConfig[status] || statusConfig.default;
  };

  const formatNotification = (notification) => {
    const config = getNotificationConfig(notification.class);
    const statusBadge = getStatusBadge(notification.status);
    const parsedData = notification.data ? JSON.parse(notification.data) : {};

    return {
      id: notification.id,
      icon: config.icon,
      bgColor: config.bgColor,
      iconColor: config.iconColor,
      category: config.category,
      title: config.label,
      message: `Operazione su dominio ${notification.domain}`,
      time: moment(notification.created_date).fromNow(),
      status: notification.status,
      statusLabel: statusBadge.label,
      statusClass: statusBadge.class,
      statusIcon: statusBadge.icon,
      isRead: notification.is_read,
      createdDate: notification.created_date,
      details: {
        'ID Messaggio': notification.message_id,
        'Dominio': notification.domain,
        'ROID': notification.roid,
        'Stato': statusBadge.label,
        'Data creazione': moment(notification.created_date).format('DD/MM/YYYY HH:mm:ss'),
        ...Object.entries(parsedData).reduce((acc, [key, value]) => {
          if (typeof value === 'object') {
            return { ...acc, ...value };
          }
          return { ...acc, [key]: value };
        }, {})
      }
    };
  };

  const toggleModal = (notification = null) => {
    setSelectedNotification(notification);
    setModalOpen(!modalOpen);
    if (notification) {
      setMenu(false);
    }
  };

  const unreadCount = notifications.filter(n => !n.isRead).length;
  return (
      <React.Fragment>
        <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="dropdown d-inline-block"
            tag="li"
        >
          <DropdownToggle
              className="btn header-item noti-icon position-relative"
              tag="button"
              id="page-header-notifications-dropdown"
          >
          <span className="notification-bell-wrapper">
            <i className="ri-notification-3-line"></i>
            {unreadCount > 0 && <span className="notification-indicator"></span>}
          </span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3 border-bottom border-light">
              <Row className="align-items-center">
                <Col>
                  <h6 className="mb-1 fw-semibold">Notifiche</h6>
                  <p className="text-muted mb-0 fs-13">
                    {unreadCount} notifiche non lette
                  </p>
                </Col>
                <div className="col-auto">
                  <button className="btn btn-link btn-sm text-decoration-none p-0">
                    Segna come lette
                  </button>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: "350px" }}>
              {notifications.map((notification) => (
                  <div
                      key={notification.id}
                      className="notification-item"
                      onClick={() => toggleModal(notification)}
                      style={{ cursor: 'pointer' }}
                  >
                    <div className="d-flex align-items-center p-3 border-bottom border-light">
                      <div className={`notification-icon me-3 ${notification.bgColor}`}>
                        <i className={`${notification.icon} ${notification.iconColor}`}></i>
                      </div>
                      <div className="flex-grow-1 min-w-0">
                        <div className="d-flex align-items-center mb-1">
                          <h6 className="mb-0 me-2 text-truncate">{notification.title}</h6>
                          <span className={`notification-badge ${notification.statusClass}`}>
                        {notification.statusLabel}
                      </span>
                        </div>
                        <p className="text-muted mb-1 fs-13 text-truncate">
                          {notification.message}
                        </p>
                        <div className="d-flex align-items-center fs-12 text-muted">
                          <i className="ri-time-line me-1"></i>
                          {notification.time}
                        </div>
                      </div>
                    </div>
                  </div>
              ))}
            </SimpleBar>

            <div className="p-1 text-center border-top border-light">
              <Link
                  to="/allNotification"
                  className="btn btn-primary btn-sm w-100 text-decoration-none fw-semibold"
              >
                Vedi tutte le notifiche
                <i className="ri-arrow-right-line ms-1 align-middle"></i>
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>

        <Modal
            isOpen={modalOpen}
            toggle={() => toggleModal()}
            centered
            size="lg"
            className="notification-modal"
        >
          {selectedNotification && (
              <>
                <ModalHeader
                    toggle={() => toggleModal()}
                    className="modal-header-custom"
                >
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex align-items-center">
                      <div className={`notification-icon-lg me-3 ${selectedNotification.bgColor}`}>
                        <i className={`${selectedNotification.icon} ${selectedNotification.iconColor}`}></i>
                      </div>
                      <div>
                        <div className="modal-title-container">
                          <h5 className="mb-1">{selectedNotification.title}</h5>
                          <div className={`status-indicator ${selectedNotification.statusClass}`}>
                            <i className={`${selectedNotification.statusIcon} me-1`}></i>
                            {selectedNotification.statusLabel}
                          </div>
                        </div>
                        <p className="text-muted mb-0 fs-13">
                          {moment(selectedNotification.createdDate).format('DD MMMM YYYY, HH:mm')}
                        </p>
                      </div>
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody className="modal-body-custom">
                  <div className="notification-content">
                    <div className="content-section">
                      <h6 className="section-title">Dettagli Operazione</h6>
                      <div className="section-content">
                        <Row>
                          <Col md="6">
                            <div className="info-group">
                              <label className="info-label">ID Operazione</label>
                              <p className="info-value">{selectedNotification.details['ID Messaggio']}</p>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="info-group">
                              <label className="info-label">Dominio</label>
                              <p className="info-value highlight">{selectedNotification.details['Dominio']}</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <div className="info-group">
                              <label className="info-label">ROID</label>
                              <p className="info-value">{selectedNotification.details['ROID']}</p>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="info-group">
                              <label className="info-label">Data Creazione</label>
                              <p className="info-value">
                                {selectedNotification.details['Data creazione']}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="content-section mt-4">
                      <h6 className="section-title">Dettagli Aggiuntivi</h6>
                      <div className="section-content">
                        <Row>
                          {Object.entries(selectedNotification.details).map(([key, value]) => {
                            if (['ID Messaggio', 'Dominio', 'ROID', 'Data creazione', 'Stato'].includes(key)) {
                              return null;
                            }
                            return (
                                <Col md="6" key={key}>
                                  <div className="info-group">
                                    <label className="info-label">{key.replace(/_/g, ' ')}</label>
                                    <p className="info-value">{value?.toString() || '-'}</p>
                                  </div>
                                </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer-custom mt-4">
                    <button
                        className="btn btn-light me-2"
                        onClick={() => toggleModal()}
                    >
                      Chiudi
                    </button>
                    <button className="btn btn-primary">
                      Segna come letta
                    </button>
                  </div>
                </ModalBody>
              </>
          )}
        </Modal>
    </React.Fragment>
  );
};

export default NotificationDropdown;
