import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Spinner,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import apiCalls from "../../contexts/apiCall";
import moment from 'moment';
import 'moment/locale/it';
import '../../assets/css/allNotifications.scss';

moment.locale('it');

const AllNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterStatus, setFilterStatus] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [modalData, setModalData] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            const data = await apiCalls.notifichePollApi.getNotifichePoll();
            if (data && Array.isArray(data)) {
                const formattedNotifications = data.map(formatNotification);
                setNotifications(formattedNotifications);
            } else {
                throw new Error("Dati non validi o vuoti");
            }
            setError(null);
        } catch (err) {
            console.error("Error fetching notifications:", err);
            setError("Errore nel caricamento delle notifiche. Contattare l'amministratore.");
        } finally {
            setLoading(false);
        }
    };

    const getNotificationConfig = (classType) => {
        const configs = {
            'domain:create': {
                icon: 'ri-add-circle-line',
                bgColor: 'bg-primary-subtle',
                iconColor: 'text-primary',
                label: 'Creazione Dominio'
            },
            'domain:update': {
                icon: 'ri-edit-line',
                bgColor: 'bg-warning-subtle',
                iconColor: 'text-warning',
                label: 'Aggiornamento Dominio'
            },
            'domain:delete': {
                icon: 'ri-delete-bin-line',
                bgColor: 'bg-danger-subtle',
                iconColor: 'text-danger',
                label: 'Eliminazione Dominio'
            },
            'default': {
                icon: 'ri-information-line',
                bgColor: 'bg-success-subtle',
                iconColor: 'text-success',
                label: 'Operazione Dominio'
            }
        };
        return configs[classType] || configs.default;
    };

    const getStatusConfig = (status) => {
        const configs = {
            'processing': {
                label: 'In elaborazione',
                class: 'badge-warning',
                icon: 'ri-loader-4-line'
            },
            'completed': {
                label: 'Completato',
                class: 'badge-success',
                icon: 'ri-check-double-line'
            },
            'failed': {
                label: 'Fallito',
                class: 'badge-danger',
                icon: 'ri-close-circle-line'
            },
            'default': {
                label: status,
                class: 'badge-info',
                icon: 'ri-information-line'
            }
        };
        return configs[status] || configs.default;
    };

    const formatNotification = (notification) => {
        const config = getNotificationConfig(notification.class);
        const statusConfig = getStatusConfig(notification.status);
        const parsedData = notification.data ? JSON.parse(notification.data) : {};

        return {
            id: notification.id,
            icon: config.icon,
            bgColor: config.bgColor,
            iconColor: config.iconColor,
            title: config.label,
            message: `Operazione su dominio ${notification.domain}`,
            time: moment(notification.created_date).fromNow(),
            date: moment(notification.created_date).format('DD MMMM YYYY, HH:mm'),
            status: notification.status,
            statusLabel: statusConfig.label,
            statusClass: statusConfig.class,
            statusIcon: statusConfig.icon,
            domain: notification.domain,
            messageId: notification.message_id,
            roid: notification.roid,
            isRead: notification.is_read,
            details: parsedData,
            created_date: notification.created_date
        };
    };

    const handleNotificationClick = (notification) => {
        setModalData(notification);
        setShowModal(true);
    };

    const formatDetailKey = (key) => {
        return key
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const filteredNotifications = notifications
        .filter(notification => {
            const matchesStatus = filterStatus === 'all' || notification.status === filterStatus;
            const matchesSearch = notification.domain.toLowerCase().includes(searchTerm.toLowerCase()) ||
                notification.title.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesStatus && matchesSearch;
        })
        .sort((a, b) => moment(b.created_date).diff(moment(a.created_date)));
    const renderContent = () => {
        if (loading) {
            return (
                <div className="text-center py-5">
                    <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
                </div>
            );
        }

        if (error) {
            return (
                <Alert color="danger" className="text-center">
                    {error}
                </Alert>
            );
        }

        if (filteredNotifications.length === 0) {
            return (
                <div className="text-center py-5">
                    <i className="ri-notification-off-line fs-1 text-muted mb-4 d-block"></i>
                    <h5 className="text-muted">Nessuna notifica trovata</h5>
                </div>
            );
        }

        return (
            <SimpleBar style={{ maxHeight: 'calc(100vh - 300px)' }}>
                <div className="notifications-list">
                    {filteredNotifications.map((notification) => (
                        <div
                            key={notification.id}
                            className={`notification-item ${!notification.isRead ? 'unread' : ''}`}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            <div className="d-flex">
                                <div className={`notification-icon me-3 ${notification.bgColor}`}>
                                    <i className={`${notification.icon} ${notification.iconColor}`}></i>
                                </div>
                                <div className="notification-content flex-grow-1">
                                    <div className="title-row">
                                        <h6>{notification.title}</h6>
                                        <div className={`status-badge ${notification.statusClass}`}>
                                            <i className={notification.statusIcon}></i>
                                            {notification.statusLabel}
                                        </div>
                                    </div>
                                    <p className="message">{notification.message}</p>
                                    <div className="meta-info">
                                        <i className="ri-time-line me-1"></i>
                                        {notification.time}
                                        {!notification.isRead && (
                                            <span className="unread-badge ms-2">
                                                Nuovo
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </SimpleBar>
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Notifiche" breadcrumbItem="Tutte le notifiche" />

                    <div className="notifications-container">
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <div className="header-filters">
                                            <Row className="align-items-center">
                                                <Col md={4}>
                                                    <h4 className="card-title mb-0">
                                                        Notifiche
                                                        {notifications.length > 0 && (
                                                            <span className="text-muted fs-14 ms-2">
                                                                ({notifications.length})
                                                            </span>
                                                        )}
                                                    </h4>
                                                </Col>
                                                <Col md={8}>
                                                    <div className="d-flex justify-content-end gap-3">
                                                        <div className="search-box position-relative" style={{ width: '280px' }}>
                                                            <i className="ri-search-line search-icon"></i>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Cerca per dominio o tipo..."
                                                                value={searchTerm}
                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                            />
                                                        </div>
                                                        <select
                                                            className="form-select filter-select"
                                                            value={filterStatus}
                                                            onChange={(e) => setFilterStatus(e.target.value)}
                                                        >
                                                            <option value="all">Tutti gli stati</option>
                                                            <option value="processing">In elaborazione</option>
                                                            <option value="completed">Completati</option>
                                                            <option value="failed">Falliti</option>
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        {renderContent()}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Modal
                isOpen={showModal}
                toggle={() => setShowModal(false)}
                className="notification-modal"
                size="lg"
            >
                {modalData && (
                    <>
                        <ModalHeader toggle={() => setShowModal(false)}>
                            <div className="modal-title">
                                <div className={`icon-wrapper ${modalData.bgColor}`}>
                                    <i className={`${modalData.icon} ${modalData.iconColor}`}></i>
                                </div>
                                <div className="title-content">
                                    <h5>{modalData.title}</h5>
                                    <p className="subtitle">{modalData.domain}</p>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="info-section">
                                <h6 className="section-title">Informazioni Generali</h6>
                                <div className="detail-row">
                                    <span className="detail-label">Stato</span>
                                    <span className={`status-badge ${modalData.statusClass}`}>
                                        <i className={modalData.statusIcon}></i>
                                        {modalData.statusLabel}
                                    </span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Data creazione</span>
                                    <span className="detail-value">{modalData.date}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">ID Messaggio</span>
                                    <span className="detail-value">{modalData.messageId}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">ROID</span>
                                    <span className="detail-value">{modalData.roid}</span>
                                </div>
                            </div>

                            <div className="info-section">
                                <h6 className="section-title">Dettagli Operazione</h6>
                                {Object.entries(modalData.details).map(([key, value]) => (
                                    <div className="detail-row" key={key}>
                                        <span className="detail-label">{formatDetailKey(key)}</span>
                                        <span className="detail-value">
                                            {typeof value === 'object' ? JSON.stringify(value) : value?.toString() || '-'}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                className="btn btn-light"
                                onClick={() => setShowModal(false)}
                            >
                                Chiudi
                            </button>
                            {!modalData.isRead && (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        // Implementare la logica per segnare come letta
                                        setShowModal(false);
                                    }}
                                >
                                    <i className="ri-check-line me-1"></i>
                                    Segna come letta
                                </button>
                            )}
                        </ModalFooter>
                    </>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default AllNotifications;

