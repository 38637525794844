import React from "react";

// Import dei componenti
import Dashboard from "../Pages/Dashboard/Admin";
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import Domini from "../Pages/Domini/Admin/Nuovi";
import DomainManagement from "../Pages/Domini/Admin/Esistenti";
import ListaClienti from "../Pages/Clienti/Lista";
import NuovoCliente from "../Pages/Clienti/Nuovo";
import DomainCalendar from "../Pages/Calender";
import DomainGetDetails from "../Pages/Domini/Admin/Dettagli";
import { AuthProtected } from "./AuthProtected";
import Error404 from "../Pages/Utility/Error404-Page";
import Error403 from "../Pages/Utility/Error403";
import UsersTable from "../Pages/Admin/Lista";
import UserEdit from "../Pages/Admin/UserEdit";
import UserCreate from "../Pages/Admin/UserCreate";
import DashboardClienti from "../Pages/Dashboard/Clienti";
import DomainManagementClienti from "../Pages/Domini/Clienti/Esistenti";
import DomainDetailsCliente from "../Pages/Domini/Clienti/Dettagli";
import NameserverPackageList from "../Pages/Settings/NameserverPackage";
import PriceListManagement from "../Pages/Settings/ListinoPrezziSetting";
import allNotifications from "../Pages/notificationPoll/allNotifications";
import AllNotifications from "../Pages/notificationPoll/allNotifications";



const authProtectedRoutes = [
    {
        path: "/",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: Dashboard,
                    cliente: DashboardClienti,
                }}
            />
        ),
    },
    {
        path: "/dashboard",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: Dashboard,
                    cliente: DashboardClienti,
                }}
            />
        ),
    },
    {
        path: "/domini",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: Domini,
                    user: Domini,
                    cliente: Domini,
                }}
            />
        ),
    },
    {
        path: "/calendario",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: DomainCalendar,
                    user: DomainCalendar,
                }}
            />
        ),
    },
    {
        path: "/manage-domini",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: DomainManagement,
                    cliente: DomainManagementClienti,
                }}
            />
        ),
    },
    {
        path: "/dettagli-domini/:id",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: DomainGetDetails,
                    cliente: DomainDetailsCliente,
                }}
            />
        ),
    },
    {
        path: "/lista-clienti",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: ListaClienti,
                    user: ListaClienti,
                }}
            />
        ),
    },
    {
        path: "/nuovo-cliente",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: NuovoCliente,
                    user: NuovoCliente,
                }}
            />
        ),
    },
    {
        path: "/admin-users",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: UsersTable
                }}
                />
        )
    },
    {
        path: "/admin-users/:id/edit",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: UserEdit
                }}
            />
        )
    },
    {
        path: "/admin-users-new",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: UserCreate
                }}
            />
        )
    },
    {
        path: "/setting-nameserver",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: NameserverPackageList,
                }}
            />
        ),
    },
    {
        path: "/setting-listini-prezzi",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: PriceListManagement,
                }}
            />
        ),

    },
    {
        path: "/allNotification",
        component: (
            <AuthProtected
                roleComponents={{
                    admin: AllNotifications,
                }}
            />
        ),

    }

];

const publicRoutes = [
    { path: "/logout", component: <Logout /> },
    { path: "/login", component: <Login /> },
    { path: "/forgot-password", component: <ForgetPasswordPage /> },
    { path: "/register", component: <Register /> },
    { path: "/404", component: <Error404 /> },
    { path: "/403", component: <Error403 /> }, // Aggiungi questa route per la pagina 403
];

export { authProtectedRoutes, publicRoutes };
