import {protectedApi} from "../api";
import * as url from "../url_config";

const notifichePollApi = {

    getNotifichePoll: async () => {
        try {
            const notifichePollData = await protectedApi.post(url.GET_NOTIFICHE_POLL);
            return notifichePollData.data.original;
        } catch (error) {
            console.error('Failed to fetch notifiche poll:', error);
            throw error;
        }
    },
}

export default notifichePollApi;
